<template>
  <div class="form" id="forms2">
    <div class="form_con">
      <h2>上传作品</h2>
      <div class="con">
        <div class="one">
          <div class="lab"><span>*</span>姓名：</div>
          <el-input v-model="name" placeholder="请输入姓名"></el-input>
        </div>
        <div class="one">
          <div class="lab"><span>*</span>手机：</div>
          <el-input
            v-model="mobile"
            placeholder="请输入手机号"
            type="number"
          ></el-input>
        </div>
        <div class="one">
          <div class="lab"><span>*</span>邮箱：</div>
          <el-input
            v-model="email"
            placeholder="请输入邮箱"
            type="email"
          ></el-input>
        </div>
        <div class="one">
          <div class="lab"><span>*</span>地址：</div>
          <el-input v-model="address" placeholder="请输入地址"></el-input>
        </div>
        <div class="one">
          <div class="lab"><span>*</span>作品名称：</div>
          <el-input v-model="title" placeholder="请输入作品名称"></el-input>
        </div>
        <div class="one">
          <div class="lab"><span>*</span>参赛主题：</div>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </div>
        <div class="one">
          <div class="lab"><span>*</span>作品封面：</div>
          <div
            :class="
              imageUrl2
                ? 'dailyWord_upload_img dailyWord_upload_img2'
                : 'dailyWord_upload_img'
            "
          >
            <template v-if="headers.token">
              <img :src="imageUrl2" v-if="imageUrl2" />
              <el-upload
                class="upload-demo"
                :show-file-list="false"
                drag
                :headers="headers"
                :action="$api.url + '/api/' + $face.defaultUpload"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload2"
                accept=".png, .jpg, .jpeg, .gif"
              >
                <img v-if="imageUrl2" :src="imageUrl2" class="up_img" />
                <i class="upload_icon"></i>
                <div class="el-upload__text">点击 / 拖拽图片文件到这里上传</div>
                <div class="el-upload__tip">
                  支持jpg、png、gif格式，RGB模式，不超过2M
                </div>
              </el-upload>
            </template>
            <div class="upload-demo" v-else @click="tips">
              <div class="el-upload-dragger2">
                <img v-if="imageUrl2" :src="imageUrl2" class="up_img" />
                <i class="upload_icon"></i>
                <div class="el-upload__text">点击 / 拖拽图片文件到这里上传</div>
                <div class="el-upload__tip">
                  支持jpg、png、gif格式，RGB模式，不超过20M
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="one">
          <div class="lab"><span>*</span>作品：</div>
          <div class="daild">
            <template v-if="headers.token">
              <el-upload
                class="upload-demo"
                :show-file-list="false"
                multiple
                drag
                :headers="headers"
                :action="$api.url + '/api/' + $face.uploadfile"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :on-progress="ingStatusFun"
                :on-error="errorFun"
                list-type="picture"
              >
                <i class="upload_icon"></i>
                <div class="el-upload__text">点击 / 拖拽图片文件到这里上传</div>
                <div class="el-upload__tip">
                  支持jpg、png、gif、mp4格式，RGB模式，不超过20M
                </div>
              </el-upload>
              <ul class="el-upload-list el-upload-list--picture">
                <li
                  tabindex="0"
                  class="el-upload-list__item is-success"
                  v-for="(item, index) of fileList"
                  :key="index"
                >
                  <div class="el-upload-list__item_img">
                    <img
                      :src="item.url"
                      alt=""
                      class="el-upload-list__item-thumbnail"
                    />
                  </div>
                  <a class="el-upload-list__item-name"
                    ><i class="el-icon-document"></i
                  ></a>
                  <el-progress
                    :percentage="item.percent"
                    v-if="item.status != 1"
                  ></el-progress>
                  <label
                    class="el-upload-list__item-status-label"
                    v-if="item.status == 1"
                  >
                    <i class="el-icon-upload-success el-icon-check"></i>
                  </label>
                  <i class="el-icon-close" @click="close(index)"></i
                  ><i class="el-icon-close-tip">按 delete 键可删除</i>
                </li>
              </ul>
            </template>
            <div class="upload-demo" v-else @click="tips">
              <div class="el-upload-dragger2">
                <i class="upload_icon"></i>
                <div class="el-upload__text">点击 / 拖拽图片文件到这里上传</div>
                <div class="el-upload__tip">
                  支持jpg、png、gif、mp4格式，RGB模式，不超过20M
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="one">
          <div class="lab"><span>*</span>设计说明：</div>
          <div style="clear: both"></div>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="desc"
            maxlength="300"
          >
          </el-input>
        </div>
      </div>
      <div class="argee">
        <el-checkbox v-model="checked"></el-checkbox>
        我已同意<span @click="seeShare">《大赛征集规则》</span>等所有条款
      </div>
      <button :class="checked ? 'btn btn2' : 'btn'" @click="submit">
        {{ text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "matchForm",
  data() {
    return {
      id: "",
      events_id: "",
      options: [],
      options_thumb: [],
      value: "",
      name: "",
      mobile: "",
      email: "",
      address: "",
      title: "",
      desc: "",
      headers: {
        token: localStorage.getItem("access_token"),
      },
      imageUrl: [],
      imageUrl2: "",
      checked: false,
      info: null,
      fullscreenLoading: true,
      text: "提交作品",
      fileList: [],
      ingStatus: false,
    };
  },
  mounted() {
    this.events_id = this.$route.query.events_id;
    this.id = this.$route.query.id;
    this.getInfo();
    if (
      this.id != "" &&
      this.id != undefined &&
      this.id != null &&
      this.headers.token
    ) {
      this.getDetail();
    }
    if (this.id) {
      setTimeout(() => {
        var odiv = document.getElementById("forms2");
        let s = this.getDistanceOfLeft(odiv).top;
        document.body.scrollTop = s;
        document.documentElement.scrollTop = s;
      }, 10);
    }
  },
  methods: {
    getInfo() {
      this.$api.POST(this.$face.match_info, { id: 1 }, (res) => {
        let options = res.data.theme_type.split(",");
        let one = [];
        for (let i in options) {
          one = [];
          one["value"] = i;
          one["label"] = options[i];

          this.options.push(one);
        }
      });
    },
    getDetail() {
      this.$api.POST(this.$face.events_works_info, { id: this.id }, (res) => {
        if (res.code == 200) {
          this.name = res.data.name;
          this.mobile = res.data.mobile;
          this.email = res.data.email;
          this.address = res.data.address;
          this.title = res.data.title;
          this.desc = res.data.introduce;
          this.value = res.data.theme;
          this.imageUrl2 = res.data.thumb;
          this.imageUrl = res.data.works;

          let arr = new Array();
          let obj = new Object();
          for (let i in res.data.works) {
            obj = {
              real_name: res.data.works[i],
              status: 1,
              url: res.data.works[i],
            };
            let tu = "jpg,jpeg,png,gif";
            let video = "mp4";

        
            if (video.indexOf(res.data.works[i].split(".")[3]) != -1) {
              obj = {
                real_name: res.data.works[i],
                status: 1,
                url: require("../../assets/images/video2.svg"),
              };
            }
            console.log(obj);
            arr.push(obj);
          }
          this.fileList = arr;
        }
      });
    },
    submit() {
        if (!this.headers.token) {
          this.$parent.$parent.loginShow();
          return;
        }
        if (this.checked == false) {
          return;
        }
        if (this.fullscreenLoading == false) {
          return;
        }
        this.fullscreenLoading = false;
        if(this.name.trim()==''){
          this.$utile.prompt("error", '请填写姓名');
          this.fullscreenLoading = true;
          return ;
        }
        if(this.mobile.trim()==''){
          this.$utile.prompt("error", '请填写手机');
          this.fullscreenLoading = true;
          return ;
        }
        if(this.email.trim()==''){
          this.$utile.prompt("error", '请填写邮箱');
          this.fullscreenLoading = true;
          return ;
        }
        if(this.address.trim()==''){
          this.$utile.prompt("error", '请填写地址');
          this.fullscreenLoading = true;
          return ;
        }
        if(this.title.trim()==''){
          this.$utile.prompt("error", '请填写作品名称');
          this.fullscreenLoading = true;
          return ;
        }
        if(this.value.trim()==''){
          this.$utile.prompt("error", '请选择参赛主题');
          this.fullscreenLoading = true;
          return ;
        }
        if(this.imageUrl2==''){
          this.$utile.prompt("error", '请上传作品封面');
          this.fullscreenLoading = true;
          return ;
        }
        if(this.imageUrl==''){
          this.$utile.prompt("error", '请上传作品');
          this.fullscreenLoading = true;
          return ;
        }
        if(this.desc.trim()==''){
          this.$utile.prompt("error", '请填写设计说明');
          this.fullscreenLoading = true;
          return ;
        }

        const params = {
          id: this.id,
          events_id: this.events_id,
          name: this.name,
          mobile: this.mobile,
          email: this.email,
          address: this.address,
          title: this.title,
          introduce: this.desc,
          thumb: this.imageUrl2,
          works: this.imageUrl,
          theme: this.value,
        };
        let url = this.$face.events_works_add;
        if (this.id != "" && this.id != undefined && this.id != null) {
          url = this.$face.events_works_edit;
        }
        this.$api.POST500(url, params, (res) => {
          if (res.code == 200) {
            this.$utile.prompt("success", "提交成功");
            setTimeout(() => {
              this.fullscreenLoading = true;
              this.$router.push({
                name: "matchWorks",
                query: {
                  events_id: 1,
                },
              });
            }, 1000);
          } else {
            this.fullscreenLoading = true;
            this.$utile.prompt("error", res.msg);
          }
        });
    },
    getDistanceOfLeft(obj) {
      var left = 0;
      var top = 0;
      while (obj) {
        left += obj.offsetLeft;
        top += obj.offsetTop;
        obj = obj.offsetParent;
      }
      return {
        left: left,
        top: top,
      };
    },
    handleAvatarSuccess(res, file) {
      // this.$utile.prompt("success", "上传成功");
      console.log(res, file);
      let urls = res.initialPreview[0];
      this.imageUrl.push(urls);

      let o = -1;
      for (let i in this.fileList) {
        if (this.fileList[i].uid == file.uid) {
          o = i;
        }
      }
      if (o != -1) {
        console.log(o);
        let obj = {
          name: urls,
          status: 1,
          url: urls,
        };

        let tu = "jpg,png,gif";
        let video = "mp4";

        if (video.indexOf(urls.split(".")[3]) != -1) {
          obj = {
            name: urls,
            status: 1,
            url: require("../../assets/images/video2.svg"),
          };
        }

        this.fileList.splice(o, 1, obj);
        console.log(this.fileList);
      }
    },
    beforeAvatarUpload(file) {
      // if (file.type!='image/jpeg' && file.type!='image/jpg' && file.type!='image/png' && file.type!='image/gif' && file.type!='video/mp4') {
      //   this.$message.error('上传文件不支持!');return false;
      // }
      let o = 0;
      for (let i in this.fileList) {
        if (this.fileList[i].uid == file.uid) {
          o = i;
        }
      }

      let obj = {
        uid: file.uid,
        status: 0,
        percent: 0,
        url: require("../../assets/images/words.svg"),
      };
      let tu = "jpg,jpeg,png,gif";
      let video = "mp4";

      if (tu.indexOf(file.name.split(".")[1]) != -1) {
        obj = {
          uid: file.uid,
          status: 0,
          percent: 0,
          url: require("../../assets/images/words.svg"),
        };
      }
      if (video.indexOf(file.name.split(".")[1]) != -1) {
        obj = {
          uid: file.uid,
          status: 0,
          percent: 0,
          url: require("../../assets/images/video2.svg"),
        };
      }

      this.fileList.push(obj);

      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 200;
      // let geshi = "jpg,png,gif,mp4";

      // if (!isJPG) {
      // this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$utile.prompt("error", "上传文件大小不能超过 20MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccess2(res, file) {
      this.$utile.prompt("success", "上传成功");
      this.imageUrl2 = res.initialPreview[0];
    },
    beforeAvatarUpload2(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      // this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$utile.prompt("error", "上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    close(index) {
      this.imageUrl.splice(index, 1);
      this.fileList.splice(index, 1);
    },
    ingStatusFun(event, file) {
      console.log("111", this.fileList);
      let o = 0;
      for (let i in this.fileList) {
        if (this.fileList[i].uid == file.uid) {
          o = i;
        }
      }

      let obj = {
        uid: file.uid,
        status: 0,
        percent: 0,
        url: file.url,
      };
      let tu = "bmp,ico,svg,ai,tif,psd,txt";
      let video = "mp4,mov,mpg,mpeg,rmvb,avi,rm,mkv,flv,wmv,mvk";

      if (tu.indexOf(file.name.split(".")[1]) != -1) {
        obj = {
          uid: file.uid,
          status: 0,
          percent: 0,
          url: require("../../assets/images/words.svg"),
        };
      }
      if (video.indexOf(file.name.split(".")[1]) != -1) {
        obj = {
          uid: file.uid,
          status: 0,
          percent: 0,
          url: require("../../assets/images/video2.svg"),
        };
      }

      let percent = parseInt(event.percent);
      obj.percent = percent == 100 ? 99 : percent;
      this.fileList.splice(o, 1, obj);
    },
    errorFun(err, file) {
      let o = 0;
      for (let i in this.fileList) {
        if (this.fileList[i].uid == file.uid) {
          o = i;
        }
      }
      let myError = err.toString(); //转字符串
      myError = myError.replace("Error: ", ""); // 去掉前面的" Error: "
      myError = JSON.parse(myError); //转对象
      this.$utile.prompt("error", myError.message);
      this.fileList.splice(o, 1);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      for (let i in this.imageUrl) {
        if (this.imageUrl[i] == file["real_name"]) {
          console.log(i);
          this.imageUrl.splice(i, 1);
        }
      }
      console.log(this.imageUrl);
    },
    handlePreview(file) {
      console.log(file);
    },
    tips() {
      this.$parent.$parent.loginShow();
      return;
    },
    seeShare() {
      let routeUrl = this.$router.resolve({
        path: "/agreement",
        query: {
          id: 13,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  watch: {
    "$store.state.userInfo"(newval) {
      this.headers.token = localStorage.getItem("access_token");
    },
  },
};
</script>

<style scoped>
.form {
  background: #0000fe;
  overflow: hidden;
}
.form_con {
  width: 1255px;
  background: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 35px 45px;
  margin: 105px auto;
}
.form_con h2 {
  font-size: 38px;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px #bfbfbf solid;
}
.form_con {
  overflow: hidden;
}
.con {
  margin-top: 82px;
  border-bottom: 2px #dfdfdf solid;
}
.one {
  line-height: 42px;
  clear: both;
  margin-bottom: 34px;
  overflow: hidden;
}
.one .lab {
  float: left;
  font-size: 22px;
  color: #404040;
  margin-right: 8px;
}
.one .lab span {
  color: #f00;
  margin-right: 5px;
}
.one >>> .el-input {
  float: left;
  width: 705px;
}
.one >>> .el-select {
  float: left;
  width: 168px;
}
.one >>> .el-select {
  float: left;
  width: 168px;
}
.one >>> .el-select .el-input__inner {
  width: 168px;
}
.one >>> .el-select .el-input {
  width: 168px;
}
.one >>> .el-input__inner {
  width: 705px;
  height: 42px;
}
.dailyWord_upload_img {
  margin: 18px 20px;
  box-sizing: border-box;
  clear: both;
}
.dailyWord_upload_img >>> .upload-demo {
  width: 100%;
  height: 326px;
  background-color: #f9f9f9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}
.dailyWord_upload_img >>> .el-upload-dragger {
  width: 461px;
  height: 208px;
  background-color: #ffffff;
  border-radius: 10px;
  border: dashed 1px #cccccc;
}
.dailyWord_upload_img .el-upload-dragger2 {
  width: 461px;
  height: 208px;
  background-color: #ffffff;
  border-radius: 10px;
  border: dashed 1px #cccccc;
  text-align: center;
  cursor: pointer;
}
.dailyWord_upload_img >>> .upload_icon {
  display: block;
  width: 53px;
  height: 53px;
  margin: 29px auto 23px;
  background: url(../../assets/images/upload.png) no-repeat;
}
.dailyWord_upload_img .upload_icon2 {
  display: block;
  width: 53px;
  height: 53px;
  margin: 29px auto 23px;
  background: url(../../assets/images/upload.png) no-repeat;
}
.dailyWord_upload_img >>> .el-upload__text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 22px;
}
.dailyWord_upload_img .el-upload__text2 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 22px;
}
.dailyWord_upload_img >>> .el-upload__tip {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999;
  line-height: 24px;
}
.dailyWord_upload_img .el-upload__tip2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999;
  line-height: 24px;
}
.dailyWord_upload_img >>> .up_img {
  max-width: 461px;
  max-height: 208px;
  margin: 0 auto;
}
.dailyWord_upload_img .up_img2 {
  max-width: 461px;
  max-height: 208px;
  margin: 0 auto;
}
.dailyWord_upload_img2 {
  position: relative;
}
.dailyWord_upload_img2 >>> .el-upload {
  width: 100%;
  height: 326px;
}
.dailyWord_upload_img2 >>> .el-upload-dragger {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.dailyWord_upload_img2 > img {
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translateY(-50%);
  transform: translateX(-50%);
  height: 326px;
  width: auto;
}
.one >>> .el-textarea {
  width: 815px;
  height: 155px;
  float: none;
  clear: both;
  margin-top: 15px;
}
.one >>> .el-textarea__inner {
  width: 815px;
  height: 155px;
  resize: none;
}
.argee {
  margin-top: 46px;
  color: #333333;
}
.argee >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #409eff !important;
  border-color: #409eff !important;
}
.argee >>> .el-checkbox__inner:hover {
  border-color: #409eff !important;
}
.argee span {
  cursor: pointer;
}
.btn {
  width: 212px;
  height: 66px;
  border-radius: 10px;
  border: none;
  background: #f2f2f2;
  margin-top: 30px;
  color: #999999;
  cursor: pointer;
  font-size: 26px;
}
.btn2 {
  background: #409eff;
  color: #fff;
}

.daild >>> .upload_icon {
  display: block;
  width: 53px;
  height: 53px;
  margin: 29px auto 23px;
  background: url(../../assets/images/upload.png) no-repeat;
}
.daild .upload_icon2 {
  display: block;
  width: 53px;
  height: 53px;
  margin: 29px auto 23px;
  background: url(../../assets/images/upload.png) no-repeat;
}
.daild >>> .el-upload__text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 22px;
}
.daild .el-upload__text2 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 22px;
}
.daild >>> .el-upload__tip {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999;
}
.daild .el-upload__tip2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999;
}
.daild .el-upload {
  display: block;
}
.daild .el-upload-dragger2 {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 360px;
  height: 180px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}
.el-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.el-upload-list--picture .el-upload-list__item {
  overflow: hidden;
  z-index: 0;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
  height: 180px;
  width: 200px;
  float: left;
  margin-right: 20px;
}
.el-upload-list__item_img {
  position: relative;
  height: 140px;
  width: 180px;
  overflow: hidden;
  margin-top: 10px;
}
.el-upload-list--picture .el-upload-list__item-thumbnail {
  vertical-align: middle;
  display: inline-block;
  width: 180px;
  height: auto;
  background-color: #fff;
  margin-left: 0 !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.el-upload-list__item-name {
  line-height: 70px;
  color: #606266;
  display: block;
  margin-right: 40px;
  overflow: hidden;
  padding-left: 4px;
  text-overflow: ellipsis;
  transition: color 0.3s;
  white-space: nowrap;
}
.el-upload-list--picture .el-upload-list__item-status-label {
  position: absolute;
  right: -17px;
  top: -7px;
  width: 46px;
  height: 26px;
  background: #13ce66;
  text-align: center;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0 1px 1px #ccc;
  display: block;
  z-index: 99;
}
.el-icon-upload-success {
  font-family: element-icons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.el-icon-check:before {
  content: "\e6da";
}
.el-upload-list .el-upload-list__item .el-progress {
  width: 100%;
}
.el-icon-close{ z-index: 999;}
</style>